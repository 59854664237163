import React, { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useAuth } from '../contexts/AuthContext';
import { 
  UserCircle, 
  Loader2, 
  AlertCircle, 
  Check, 
  Edit, 
  CreditCard, 
  Lock, 
  MessageCircle, 
  CheckCircle 
} from 'lucide-react';
import apiService from '../services/api';
import { translations } from '../translations/translations';

const MyAccountView = () => {
  const { user, refreshUser } = useAuth();
  const { language } = useLanguage();
  const isRTL = language === 'he';
  const [isAnnual, setIsAnnual] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const t = (key) => {
    if (translations[key] && translations[key][language]) {
      return translations[key][language];
    }
    return `Missing translation: ${key}`;
  };

  const plans = {
    pro: {
      name: 'Pro',
      monthlyPrice: 50,
      yearlyPrice: 540,
      features: [
        'integrations',
        '5,000 messages/month',
        'WhatsApp & SMS support',
        'Basic analytics'
      ]
    },
    gold: {
      name: 'Gold',
      monthlyPrice: 200,
      yearlyPrice: 2000,
      features: [
        'All Pro features',
        '30,000 messages/month',
        'Priority support',
        'Advanced analytics',
        'Custom integrations'
      ]
    }
  };

  const [vendorData, setVendorData] = useState({
    plan_type: 'free',
    billing_type: 'monthly'
  });

  const [formData, setFormData] = useState({
    company_name: '',
    personal_name: '',
    phone: '',
    address: ''
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [showProPlanModal, setShowProPlanModal] = useState(false);
  const [isProcessingPlan, setIsProcessingPlan] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setIsLoading(true);
        const response = await apiService.request('vendors/me', {
          method: 'GET'
        });

        console.log('User details:', response); // Debug log
        setVendorData({
          plan_type: response.plan_type || 'free',
          billing_type: response.billing_type || 'monthly'
        });
        setFormData({
          company_name: response.name || '',
          personal_name: response.contact_person || '',
          phone: response.phone || '',
          address: response.address || ''
        });
      } catch (err) {
        console.error('Error fetching user details:', err);
        setError(t('errorFetchingVendors'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage('');
    setIsSaving(true);

    try {
      const updatedDetails = await apiService.request('vendors/me', {
        method: 'PUT',
        body: JSON.stringify(formData)
      });
      
      const updatedUserData = {
        ...user,
        ...updatedDetails
      };
      localStorage.setItem('userData', JSON.stringify(updatedUserData));
      await refreshUser();

      setSuccessMessage(t('vendorUpdated'));
      setIsEditing(false);
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      setError(t('errorUpdatingVendor'));
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setFormData({
      company_name: user?.company_name || '',
      personal_name: user?.personal_name || '',
      phone: user?.phone || '',
      address: user?.address || ''
    });
    setError(null);
    setIsEditing(false);
  };

  const handlePlanSelection = async () => {
    if (!selectedPlan) return;
    
    try {
      setIsProcessingPlan(true);
      setError(null);

      const planData = {
        plan_type: selectedPlan,
        billing_type: isAnnual ? 'yearly' : 'monthly'
      };

      const response = await apiService.request(`vendors/${user?.vendor_id}/plan`, {
        method: 'PUT',
        body: JSON.stringify(planData)
      });

      if (response) {
        setVendorData({
          plan_type: selectedPlan,
          billing_type: isAnnual ? 'yearly' : 'monthly'
        });
        setSuccessMessage(t('planUpdateSuccess'));
        setShowProPlanModal(false);
      }
    } catch (err) {
      setError(t('planUpdateError'));
    } finally {
      setIsProcessingPlan(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
      </div>
    );
  }

  return (
    <div className="p-6 max-w-2xl mx-auto" dir={isRTL ? 'rtl' : 'ltr'}>
      <div className="mb-6">
        <div className="flex items-center gap-3">
          <UserCircle className="h-8 w-8 text-gray-600" />
          <h2 className="text-2xl font-bold">{t('myaccount')}</h2>
        </div>
      </div>

      {successMessage && (
        <div className="mb-6 bg-green-50 border border-green-400 rounded-lg p-4 flex items-center gap-2">
          <Check className="h-5 w-5 text-green-600" />
          <p className="text-green-700">{successMessage}</p>
        </div>
      )}

      {error && (
        <div className="mb-6 bg-red-50 border border-red-400 rounded-lg p-4 flex items-center gap-2">
          <AlertCircle className="h-5 w-5 text-red-600" />
          <p className="text-red-700">{error}</p>
        </div>
      )}

      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">{t('vendorDetails')}</h3>
            {!isEditing && (
              <button
                onClick={() => setIsEditing(true)}
                className="flex items-center gap-2 text-blue-600 hover:text-blue-700"
              >
                <Edit className="h-4 w-4" />
                {t('update')}
              </button>
            )}
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('vendorName')}
              </label>
              <input
                type="text"
                value={formData.company_name}
                onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                required
                disabled={!isEditing}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('contactPerson')}
              </label>
              <input
                type="text"
                value={formData.personal_name}
                onChange={(e) => setFormData({ ...formData, personal_name: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                required
                disabled={!isEditing}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('phone')}
              </label>
              <input
                type="tel"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                required
                disabled={!isEditing}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('address')}
              </label>
              <textarea
                value={formData.address}
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                rows={3}
                required
                disabled={!isEditing}
              />
            </div>

            {isEditing && (
              <div className={`flex justify-end gap-3 ${isRTL ? 'flex-row-reverse' : ''}`}>
                <button
                  type="button"
                  onClick={handleCancel}
                  className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50"
                  disabled={isSaving}
                >
                  {t('cancel')}
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                    disabled:bg-blue-400 flex items-center gap-2"
                  disabled={isSaving}
                >
                  {isSaving && <Loader2 className="h-4 w-4 animate-spin" />}
                  {isSaving ? t('saving') : t('saveChanges')}
                </button>
              </div>
            )}
          </form>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <CreditCard className="h-6 w-6 text-blue-600" />
              <h3 className="text-lg font-semibold">{t('choosePlan')}</h3>
            </div>
            <button
              onClick={() => setShowProPlanModal(true)}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                flex items-center gap-2"
            >
              <Lock className="h-4 w-4" />
              {t('updatePlan')}
            </button>
          </div>
          
          <div className="text-gray-600">
            <p>{t('currentPlan')}: {(vendorData.plan_type || 'free').charAt(0).toUpperCase() + (vendorData.plan_type || 'free').slice(1)}</p>
            <p>{t('billingCycle')}: {vendorData.billing_type === 'yearly' ? t('annual') : t('monthly')}</p>
          </div>
        </div>
      </div>

      {showProPlanModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-4xl w-full mx-4">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-2xl font-bold">{t('choosePlan')}</h3>
              <button
                onClick={() => setShowProPlanModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>

            <div className="flex justify-center items-center gap-4 mb-8">
              <span className={`text-sm ${!isAnnual ? 'font-bold' : 'text-gray-500'}`}>
                {t('monthly')}
              </span>
              <button
                onClick={() => setIsAnnual(!isAnnual)}
                className={`relative w-14 h-7 rounded-full transition-colors duration-200 ease-in-out
                  ${isAnnual ? 'bg-blue-600' : 'bg-gray-200'}`}
              >
                <span
                  className={`absolute top-1 left-1 w-5 h-5 bg-white rounded-full transition-transform duration-200 ease-in-out
                    ${isAnnual ? 'transform translate-x-7' : ''}`}
                />
              </button>
              <span className={`text-sm ${isAnnual ? 'font-bold' : 'text-gray-500'}`}>
                {t('annual')}
              </span>
            </div>

            <div className="grid md:grid-cols-2 gap-6">
              {Object.entries(plans).map(([planType, plan]) => (
                <div 
                  key={planType}
                  className={`p-6 rounded-lg border-2 transition-all cursor-pointer
                    ${selectedPlan === planType
                      ? 'border-blue-600 ring-2 ring-blue-600' 
                      : 'border-gray-200 hover:border-blue-300'}`}
                  onClick={() => setSelectedPlan(planType)}
                >
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h4 className="text-xl font-bold">{plan.name}</h4>
                      <p className="text-gray-600">{t(`${planType}Description`)}</p>
                    </div>
                    <div className="text-right">
                      <div className="text-2xl font-bold">
                        ${(isAnnual ? Math.round(plan.yearlyPrice / 12) : plan.monthlyPrice).toLocaleString()}
                        <span className="text-sm font-normal text-gray-600">
                          /{t('month')}
                        </span>
                      </div>
                      {isAnnual && (
                        <div className="text-sm text-gray-600">
                          ${plan.yearlyPrice.toLocaleString()}/{t('year')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="space-y-3">
                    {plan.features.map((feature, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <CheckCircle className="h-5 w-5 text-green-500" />
                        <span>{feature}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <div className="flex items-center gap-2 mt-6 p-4 bg-blue-50 rounded-lg">
              <MessageCircle className="h-5 w-5 text-blue-600 flex-shrink-0" />
              <p className="text-sm text-blue-600">
                {t('annualBillingMessage')}
              </p>
            </div>

            <div className="mt-8 flex justify-end gap-4">
              <button
                onClick={() => setShowProPlanModal(false)}
                className="px-6 py-2 text-gray-600 hover:text-gray-800"
              >
                {t('cancel')}
              </button>
              <button
                onClick={handlePlanSelection}
                disabled={!selectedPlan || isProcessingPlan}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                  disabled:bg-blue-300 flex items-center gap-2"
              >
                {isProcessingPlan && <Loader2 className="h-4 w-4 animate-spin" />}
                {isProcessingPlan 
                  ? t('processing')
                  : t('confirmPlanChange')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyAccountView;