import React from 'react';
import { Scale, AlertCircle, Clock, Smartphone, Trash2 } from 'lucide-react';

const ScaleList = ({ scales, measurements, onDeleteClick }) => {
  const formatDate = (timestamp) => {
    if (!timestamp) return 'No data';
    return new Date(timestamp).toLocaleString();
  };

  const getActivityStatus = (measurement) => {
    if (!measurement?.timestamp) return false;
    const lastMeasurement = new Date(measurement.timestamp);
    const oneDayAgo = new Date();
    const oneHourAgo = new Date();
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);
    oneHourAgo.setHours(oneHourAgo.getHours() - 1);
    
    if (lastMeasurement > oneHourAgo) return 'bg-green-500';
    if (lastMeasurement > oneDayAgo) return 'bg-yellow-500';
    if (lastMeasurement < oneDayAgo) return 'bg-red-500';
    return 'bg-gray-500';
  };

  const getStatusColor = (measurement) => {
    if (!measurement) return 'text-gray-400';
    return 'text-green-600';
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {scales.map((scale) => {
        const measurement = measurements[scale.scale_id];
        const statusColor = getStatusColor(measurement);
        
        return (
          <div key={scale.scale_id} className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow relative">
            <div className="absolute top-2 right-2 flex items-center gap-2">
              <div className={`w-3 h-3 rounded-full ${getActivityStatus(measurement)} shadow-sm`} 
                   title={`Last measurement: ${formatDate(measurement?.timestamp)}`}
              />
              <button
                onClick={() => onDeleteClick(scale)}
                className="p-1 text-gray-400 hover:text-red-600 hover:bg-red-50 rounded-full transition-colors"
                aria-label="Delete scale"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            </div>
            
            <div className="flex justify-between items-start mb-4">
              <div className="flex items-center gap-2">
                <div className="p-2 bg-blue-100 rounded-lg">
                  <Scale className="h-5 w-5 text-blue-600" />
                </div>
                <div>
                  <h3 className="text-lg font-medium">{scale.name || `Scale ${scale.scale_id}`}</h3>
                  <p className="text-sm text-gray-500">ID: {scale.scale_id}</p>
                </div>
              </div>
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                ${scale.is_active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                {scale.is_active ? 'Active' : 'Inactive'}
              </span>
            </div>

            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Smartphone className="h-4 w-4 text-gray-400" />
                  <span className="text-sm text-gray-600">Last Reading</span>
                </div>
                <span className={`font-medium ${statusColor}`}>
                  {measurement?.weight ? `${measurement.weight} kg` : 'No data'}
                </span>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Clock className="h-4 w-4 text-gray-400" />
                  <span className="text-sm text-gray-600">Last Update</span>
                </div>
                <span className="text-sm text-gray-500">
                  {formatDate(measurement?.timestamp)}
                </span>
              </div>

              {measurement?.error && (
                <div className="flex items-center gap-2 mt-2 p-2 bg-red-50 rounded-lg">
                  <AlertCircle className="h-4 w-4 text-red-500" />
                  <span className="text-sm text-red-600">{measurement.error}</span>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ScaleList;