import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../translations/translations';
import { Package, AlertCircle, Loader2, RefreshCw, Search, ChevronDown, ChevronUp, CheckCircle, XCircle, Plus } from 'lucide-react';
import apiService from '../../services/api';
import OrderDetails from './OrderDetails';
import OrderModal from './OrderModal';
import debounce from 'lodash/debounce';

const ITEMS_PER_PAGE = 20;
const DEBOUNCE_DELAY = 500;

const OrderStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected'
};

const StatusBadge = ({ status }) => {
  const getStatusStyles = () => {
    switch (status) {
      case OrderStatus.PENDING:
        return 'bg-yellow-100 text-yellow-800';
      case OrderStatus.APPROVED:
        return 'bg-green-100 text-green-800';
      case OrderStatus.REJECTED:
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusText = () => {
    switch (status) {
      case OrderStatus.PENDING:
        return 'Pending Approval';
      case OrderStatus.APPROVED:
        return 'Approved';
      case OrderStatus.REJECTED:
        return 'Rejected';
      default:
        return status;
    }
  };

  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyles()}`}>
      {getStatusText()}
    </span>
  );
};

const OrdersView = () => {
  const [orders, setOrders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [expandedOrder, setExpandedOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [pageToken, setPageToken] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  
  const observerRef = useRef(null);
  const loadingRef = useRef(false);
  const debouncedFilterChangeRef = useRef(null);
  
  const { language } = useLanguage();
  const isRTL = language === 'he';

  const t = (key) => {
    if (translations[key] && translations[key][language]) {
      return translations[key][language];
    }
    return `Missing translation: ${key}`;
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await apiService.getCustomers();
        setCustomers(response);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);

  const fetchOrders = useCallback(async (isLoadingMore = false, currentPageToken = null, filters = { status: statusFilter, search: searchTerm }) => {
    if (loadingRef.current) return;
    
    loadingRef.current = true;

    if (!isLoadingMore) {
      setIsFilterLoading(true);
    } else {
      setIsLoading(true);
    }

    setError(null);

    try {
      const queryParams = new URLSearchParams();
      queryParams.append('limit', ITEMS_PER_PAGE.toString());
      
      if (currentPageToken) {
        queryParams.append('start_key', currentPageToken);
      }
      
      if (filters.status && filters.status !== 'all') {
        queryParams.append('status', filters.status);
      }

      if (filters.search !== undefined) {
        const trimmedSearch = filters.search.trim();
        if (trimmedSearch) {
          queryParams.append('search', trimmedSearch);
        }
      }
      
      const response = await apiService.request(`orders?${queryParams.toString()}`, { 
        method: 'GET' 
      });

      const newOrders = response?.orders || [];
      const newPageToken = response?.next_page_token;

      if (isLoadingMore && currentPageToken) {
        setOrders(prev => [...prev, ...newOrders]);
      } else {
        setOrders(newOrders);
      }
      
      setPageToken(newPageToken);
      setHasMore(!!newPageToken);

    } catch (err) {
      setError(err.message || 'Failed to fetch orders');
      if (!currentPageToken) {
        setOrders([]);
      }
      setHasMore(false);
    } finally {
      setIsLoading(false);
      setIsFilterLoading(false);
      setIsRefreshing(false);
      loadingRef.current = false;
    }
  }, [statusFilter, searchTerm]);

  const handleApproveOrder = async (orderId) => {
    try {
      await apiService.request(`orders/${orderId}/approve`, {
        method: 'POST'
      });
      
      setPageToken(null);
      fetchOrders(false, null, { status: statusFilter, search: searchTerm });
    } catch (err) {
      setError(err.message || 'Failed to approve order');
    }
  };

  const handleRejectOrder = async (orderId) => {
    try {
      await apiService.request(`orders/${orderId}/reject`, {
        method: 'POST'
      });
      
      setPageToken(null);
      fetchOrders(false, null, { status: statusFilter, search: searchTerm });
    } catch (err) {
      setError(err.message || 'Failed to reject order');
    }
  };

  const lastOrderElementRef = useCallback(node => {
    if (isLoading || isFilterLoading) return;

    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting && hasMore && !loadingRef.current && pageToken) {
        fetchOrders(true, pageToken);
      }
    }, {
      rootMargin: '200px',
      threshold: 0.1
    });

    if (node) {
      observerRef.current.observe(node);
    }
  }, [isLoading, isFilterLoading, hasMore, pageToken, fetchOrders]);

  useEffect(() => {
    debouncedFilterChangeRef.current = debounce((filters) => {
      setOrders([]);
      setPageToken(null);
      fetchOrders(false, null, filters);
    }, DEBOUNCE_DELAY);

    return () => {
      if (debouncedFilterChangeRef.current) {
        debouncedFilterChangeRef.current.cancel();
      }
    };
  }, [fetchOrders]);

  useEffect(() => {
    if (debouncedFilterChangeRef.current) {
      debouncedFilterChangeRef.current({ status: statusFilter, search: searchTerm });
    }
  }, [statusFilter, searchTerm]);

  const handleRefresh = () => {
    setIsRefreshing(true);
    setPageToken(null);
    fetchOrders(false, null, { status: statusFilter, search: searchTerm });
  };

  const formatCurrency = (amount) => {
    if (!amount) return '₪0';
    return new Intl.NumberFormat(language === 'he' ? 'he-IL' : 'en-US', {
      style: 'currency',
      currency: 'ILS'
    }).format(amount);
  };

  if (isLoading && orders.length === 0) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto" dir={isRTL ? 'rtl' : 'ltr'}>
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold flex items-center gap-2">
              <Package className="h-6 w-6" />
              {t("ordersTitle")}
            </h2>
            <p className="text-gray-600 mt-1">Manage and approve customer orders</p>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setIsModalOpen(true)}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              disabled={isRefreshing}
            >
              <Plus className="h-5 w-5" />
              {t("addNewOrder")}
            </button>
            <button
              onClick={handleRefresh}
              className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-800"
              disabled={isRefreshing || isFilterLoading}
            >
              <RefreshCw className={`h-5 w-5 ${isRefreshing ? 'animate-spin' : ''}`} />
              Refresh
            </button>
          </div>
        </div>
      </div>

      <div className="mb-6 flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <input
            type="text"
            placeholder="Search orders..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            disabled={isFilterLoading}
          />
          <div className="absolute left-3 top-2.5">
            {isFilterLoading ? (
              <Loader2 className="h-5 w-5 animate-spin text-gray-400" />
            ) : (
              <Search className="h-5 w-5 text-gray-400" />
            )}
          </div>
        </div>
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
          disabled={isFilterLoading}
        >
          <option value="all">All Status</option>
          {Object.values(OrderStatus).map(status => (
            <option key={status} value={status}>{status}</option>
          ))}
        </select>
      </div>

      {error && (
        <div className="mb-6 bg-red-50 border border-red-400 rounded-lg p-4 flex items-center">
          <AlertCircle className="h-5 w-5 text-red-600 mr-2" />
          <p className="text-red-700">{error}</p>
        </div>
      )}

      <div className="bg-white rounded-lg shadow overflow-hidden">
        {!isFilterLoading && orders.length > 0 && (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Customer
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Items
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Order Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Notes
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {orders.map((order, index) => (
                <React.Fragment key={order.order_id || Math.random()}>
                  <tr 
                    ref={index === orders.length - 1 ? lastOrderElementRef : null}
                    className="hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.customer_name || 'Unknown Customer'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <StatusBadge status={order.status || OrderStatus.PENDING} />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {(order.items && Array.isArray(order.items) ? order.items.length : 0) || '0'} items
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatCurrency(order.total_amount)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.order_date}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 max-w-xs truncate">
                      {order.notes || ''}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex justify-center space-x-2">
                        {order.status === OrderStatus.PENDING && (
                          <>
                            <button
                              onClick={() => handleApproveOrder(order.order_id)}
                              className="text-green-600 hover:text-green-800"
                              title="Approve Order"
                            >
                              <CheckCircle className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleRejectOrder(order.order_id)}
                              className="text-red-600 hover:text-red-800"
                              title="Reject Order"
                            >
                              <XCircle className="h-5 w-5" />
                            </button>
                          </>
                        )}
                        <button
                          onClick={() => setExpandedOrder(expandedOrder === order.order_id ? null : order.order_id)}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          {expandedOrder === order.order_id ? (
                            <ChevronUp className="h-5 w-5" />
                          ) : (
                            <ChevronDown className="h-5 w-5" />
                          )}
                        </button>
                      </div>
                    </td>
                  </tr>
                  {expandedOrder === order.order_id && (
                    <tr>
                      <td colSpan="7" className="px-6 py-4">
                      <OrderDetails 
                        items={order.items || []} 
                        customerName={order.customer_name}
                        status={order.status}
                        orderId={order.order_id}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}

        {!isFilterLoading && orders.length === 0 && !error && (
          <div className="text-center py-12">
            <Package className="mx-auto h-12 w-12 text-gray-400 mb-4" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No orders found</h3>
            <p className="mt-1 text-sm text-gray-500">
              {searchTerm || statusFilter !== 'all'
                ? 'Try adjusting your filters'
                : 'No orders available at the moment.'}
            </p>
          </div>
        )}

        {hasMore && !isFilterLoading && orders.length > 0 && isLoading && (
          <div className="px-6 py-8 border-t border-gray-200 flex justify-center">
            <div className="h-8 flex items-center justify-center">
              <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
            </div>
          </div>
        )}
      </div>

      {isModalOpen && (
        <OrderModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={async (orderData) => {
            try {
              await apiService.request('orders', {
                method: 'POST',
                body: JSON.stringify(orderData)
              });
              setPageToken(null);
              fetchOrders(false, null, { status: statusFilter, search: searchTerm });
            } catch (error) {
              setError(error.message || 'Error creating order');
              throw error;
            }
          }}
          customers={customers}
        />
      )}
    </div>
  );
};

export default OrdersView;