import React, { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../translations/translations';
import { Package, Check, Loader2 } from 'lucide-react';
import apiService from '../../services/api';

const OrderDetails = ({ items: initialItems, customerName, status, orderId }) => {
  const { language } = useLanguage();
  const [items, setItems] = useState(initialItems.map(item => ({
    ...item,
    delivered: false,
    isDelivering: false
  })));
  const [isDeliveringAll, setIsDeliveringAll] = useState(false);
  const [error, setError] = useState(null);

  const t = (key) => translations[key]?.[language] || `Missing translation: ${key}`;

  const handleDeliverItem = async (itemId) => {
    setError(null);
    const updatedItems = [...items];
    const itemIndex = updatedItems.findIndex(item => item.id === itemId);
    if (itemIndex === -1) return;

    updatedItems[itemIndex].isDelivering = true;
    setItems(updatedItems);

    try {
      await apiService.request(`orders/${orderId}/items/${itemId}/deliver`, {
        method: 'POST'
      });

      updatedItems[itemIndex] = {
        ...updatedItems[itemIndex],
        delivered: true,
        isDelivering: false
      };
      setItems(updatedItems);
    } catch (err) {
      setError(err.message || 'Failed to mark item as delivered');
      updatedItems[itemIndex].isDelivering = false;
      setItems(updatedItems);
    }
  };

  const handleDeliverAll = async () => {
    setError(null);
    setIsDeliveringAll(true);

    try {
      await apiService.request(`orders/${orderId}/deliver-all`, {
        method: 'POST'
      });

      const updatedItems = items.map(item => ({
        ...item,
        delivered: true
      }));
      setItems(updatedItems);
    } catch (err) {
      setError(err.message || 'Failed to mark items as delivered');
    } finally {
      setIsDeliveringAll(false);
    }
  };

  if (!Array.isArray(items)) {
    return null;
  }

  const allDelivered = items.every(item => item.delivered);

  return (
    <div className="p-4 bg-gray-50 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h4 className="font-medium">Order Items:</h4>
        {!allDelivered && (
          <button
            onClick={handleDeliverAll}
            disabled={isDeliveringAll}
            className="flex items-center gap-2 px-3 py-1.5 bg-orange-600 text-white rounded-lg hover:bg-orange-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isDeliveringAll ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <Check className="h-4 w-4" />
            )}
            {t('deliveredAll')}
          </button>
        )}
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <div className="space-y-2">
        {items.map((item, index) => (
          <div key={index} className="flex justify-between items-center bg-white p-3 rounded-lg shadow-sm">
            <div className="flex-1">
              <span className="font-medium">
                {item.item_external_id} - {item.item_name || 'Unknown Item'}
              </span>
              <div className="text-sm text-gray-500 space-x-4">
                <span>Price: ₪{item.price || 0}</span>
                <span>Quantity: {item.quantity || 0}</span>
                <span className="font-medium">Total: ₪{item.total || 0}</span>
              </div>
            </div>
            <div className="flex items-center gap-3">
              {item.delivered ? (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  <Check className="h-3 w-3 mr-1" />
                  {t('delivered')}
                </span>
              ) : (
                <button
                  onClick={() => handleDeliverItem(item.id)}
                  disabled={item.isDelivering}
                  className="flex items-center gap-1 px-2.5 py-1 bg-orange-600 text-white text-sm rounded-lg hover:bg-orange-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {item.isDelivering ? (
                    <Loader2 className="h-3 w-3 animate-spin" />
                  ) : (
                    <Check className="h-3 w-3" />
                  )}
                  {t('delivered')}
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderDetails;