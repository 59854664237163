import React, { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/translations';
import { 
  Package, 
  AlertCircle, 
  Loader2, 
  RefreshCw,
  Search,
  Pencil,
  Image,
  Tags,
  Check,
  X,
  Heart
} from 'lucide-react';
import apiService from '../services/api';
import ItemModal from './modals/ItemModal';

const ItemsView = () => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadingFavorites, setLoadingFavorites] = useState(new Set());

  const { language } = useLanguage();
  const t = (key) => translations[key]?.[language] || `Missing translation: ${key}`;
  const isRTL = language === 'he';

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async (showLoadingState = true) => {
    if (showLoadingState) {
      setIsLoading(true);
    }
    
    try {
      const response = await apiService.request('items', {
        method: 'GET'
      });
      setItems(response || []);
      setError(null);
    } catch (err) {
      console.error('Error fetching items:', err);
      setError(t('failedToFetchItems'));
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  };

  const handleRefresh = () => {
    setIsRefreshing(true);
    fetchItems(false);
  };

  const handleItemSave = async (itemData) => {
    try {
      if (selectedItem) {
        await apiService.request(`items/${itemData.item_id}`, {
          method: 'PUT',
          body: JSON.stringify(itemData)
        });
        showSuccessMessage(t('itemUpdated'));
      } else {
        await apiService.request('items', {
          method: 'POST',
          body: JSON.stringify(itemData)
        });
        showSuccessMessage(t('itemCreated'));
      }
      await fetchItems(false);
      setIsModalOpen(false);
    } catch (error) {
      throw error;
    }
  };

  const handleToggleAvailability = async (itemId, currentStatus) => {
    try {
      await apiService.request(`items/${itemId}/toggle-availability`, {
        method: 'POST',
        body: JSON.stringify({ is_available: !currentStatus })
      });
      
      setItems(prevItems => prevItems.map(item => 
        item.item_id === itemId 
          ? { ...item, is_available: !item.is_available }
          : item
      ));
      
      showSuccessMessage(!currentStatus ? t('itemEnabled') : t('itemDisabled'));
    } catch (error) {
      setError(t('failedToUpdateAvailability'));
      console.error('Error toggling availability:', error);
    }
  };

  const handleToggleFavorite = async (itemId, currentStatus) => {
    setLoadingFavorites(prev => {
      const next = new Set(prev);
      next.add(itemId);
      return next;
    });
    
    try {
      await apiService.request(`items/${itemId}/favorite`, {
        method: 'POST',
        body: JSON.stringify({ is_favorite: !currentStatus })
      });
      
      setItems(prevItems => prevItems.map(item => 
        item.item_id === itemId 
          ? { ...item, is_favorite: !item.is_favorite }
          : item
      ));
      
      showSuccessMessage(!currentStatus ? t('itemFavorited') : t('itemUnfavorited'));
    } catch (error) {
      setError(t('failedToUpdateFavorite'));
      console.error('Error toggling favorite:', error);
    } finally {
      setLoadingFavorites(prev => {
        const next = new Set(prev);
        next.delete(itemId);
        return next;
      });
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const showSuccessMessage = (message) => {
    setSuccessMessage(message);
    setTimeout(() => setSuccessMessage(''), 5000);
  };

  const filteredItems = items.filter(item => 
    item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.item_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.category?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto" dir={isRTL ? 'rtl' : 'ltr'}>
      {successMessage && (
        <div className="fixed top-6 left-1/2 -translate-x-1/2 z-50 w-full max-w-md">
          <div className="mx-4 bg-green-50 border border-green-400 rounded-lg p-4 shadow-lg">
            <div className="flex items-center gap-2">
              <Check className="h-5 w-5 text-green-600" />
              <p className="text-green-700">{successMessage}</p>
            </div>
          </div>
        </div>
      )}

      {error && (
        <div className="fixed top-6 left-1/2 -translate-x-1/2 z-50 w-full max-w-md">
          <div className="mx-4 bg-red-50 border border-red-400 rounded-lg p-4 shadow-lg">
            <div className="flex items-center gap-2">
              <AlertCircle className="h-5 w-5 text-red-600" />
              <p className="text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      <div className="mb-6">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold flex items-center gap-2">
              <Package className="h-6 w-6" />
              {t('items')}
            </h2>
            <p className="text-gray-600 mt-1">{t('itemsDescription')}</p>
          </div>
          <div className="flex gap-2">
            <button
              onClick={handleRefresh}
              className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-800"
              disabled={isRefreshing}
            >
              <RefreshCw className={`h-5 w-5 ${isRefreshing ? 'animate-spin' : ''}`} />
              {t('refresh')}
            </button>
            <button
              onClick={() => {
                setSelectedItem(null);
                setIsModalOpen(true);
              }}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Package className="h-5 w-5" />
              {t('addNewItem')}
            </button>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <div className="relative">
          <input
            type="text"
            placeholder={t('searchItemsPlaceholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="relative overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20">
                  ITEM CODE
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-48">
                  NAME
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-40">
                  DESCRIPTION
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                  CATEGORY
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20">
                  IMAGE
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                  STATUS
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-24">
                  FAVORITE
                </th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider w-24">
                  ACTIONS
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredItems.map((item) => (
                <tr key={item.external_id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {item.external_id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item.name}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <div className="max-w-[160px] truncate">
                      {item.description || '-'}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <div className="flex items-center gap-2">
                      <Tags className="h-4 w-4 text-gray-400" />
                      {item.category || t('uncategorized')}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {item.image_url ? (
                      <div className="relative w-10 h-10 rounded overflow-hidden">
                        <img 
                          src={item.image_url} 
                          alt={item.name}
                          className="w-full h-full object-cover"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/api/placeholder/40/40';
                          }}
                        />
                      </div>
                    ) : (
                      <Image className="h-5 w-5 text-gray-400" />
                    )}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <button
                      onClick={() => handleToggleAvailability(item.item_id, item.is_available)}
                      className={`inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-xs font-medium
                        ${item.is_available 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'}`}
                    >
                      {item.is_available ? <Check className="h-3 w-3" /> : <X className="h-3 w-3" />}
                      {item.is_available ? t('available') : t('unavailable')}
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center text-sm">
                    <button
                      onClick={() => handleToggleFavorite(item.item_id, item.is_favorite)}
                      disabled={loadingFavorites.has(item.item_id)}
                      className={`p-2 rounded-full transition-colors ${
                        item.is_favorite 
                          ? 'text-red-600 hover:bg-red-50' 
                          : 'text-gray-400 hover:text-red-600 hover:bg-red-50'
                      } ${loadingFavorites.has(item.item_id) ? 'opacity-50 cursor-not-allowed' : ''}`}
                      title={item.is_favorite ? t('removeFromFavorites') : t('addToFavorites')}
                    >
                      {loadingFavorites.has(item.item_id) ? (
                        <Loader2 className="h-5 w-5 animate-spin" />
                      ) : (
                        <Heart 
                          className={`h-5 w-5 ${item.is_favorite ? 'fill-current' : ''}`}
                        />
                      )}
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    <div className="flex justify-center space-x-2">
                      <button
                        onClick={() => handleEdit(item)}
                        className="inline-flex items-center px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                      >
                        <Pencil className="h-4 w-4" />
                        <span className="ml-2">Edit</span>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {filteredItems.length === 0 && !error && (
            <div className="text-center py-12">
              <Package className="mx-auto h-12 w-12 text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900">{t('noItems')}</h3>
              <p className="mt-2 text-gray-500">
                {searchTerm ? t('trySearching') : t('addNewItemPrompt')}
              </p>
            </div>
          )}
        </div>
      </div>

      <ItemModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedItem(null);
        }}
        onSubmit={handleItemSave}
        initialData={selectedItem}
      />
    </div>
  );
};

export default ItemsView;