import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../translations/translations';
import { X, Loader2, AlertCircle, Image, LayoutGrid, Tags } from 'lucide-react';

const CATEGORIES = [
  'Coffee Blend',
  'Accesories',
  'Others'
];

const ItemModal = ({ isOpen, onClose, onSubmit, initialData = null }) => {
  const { language } = useLanguage();
  const t = (key) => translations[key]?.[language] || `Missing translation: ${key}`;
  const isRTL = language === 'he';

  const [formData, setFormData] = useState({
    item_id: '',
    name: '',
    description: '',
    image_url: '',
    category: '',
    is_available: true
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (initialData) {
        setFormData({
          external_id: initialData.external_id || '',
          item_id: initialData.item_id || '',
          name: initialData.name || '',
          description: initialData.description || '',
          image_url: initialData.image_url || '',
          category: initialData.category || '',
          is_available: initialData.is_available ?? true
        });
      } else {
        setFormData({
          item_id: '',
          name: '',
          description: '',
          image_url: '',
          category: '',
          is_available: true
        });
      }
      console.log('initialData:', initialData); // Debug log
      setErrors({});
    }
  }, [isOpen, initialData]);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.item_id.trim()) {
      newErrors.item_id = 'Item ID is required';
    }
    
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (formData.image_url && !isValidUrl(formData.image_url)) {
      newErrors.image_url = 'Please enter a valid URL';
    }

    if (!formData.category) {
      newErrors.category = 'Category is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      await onSubmit({
        ...formData,
        item_id: formData.item_id.trim(),
        name: formData.name.trim(),
        description: formData.description.trim(),
        image_url: formData.image_url.trim()
      });
      onClose();
    } catch (err) {
      console.error('Submission error:', err);
      setErrors({ submit: err.message || 'Failed to save item' });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div 
        className="bg-white rounded-lg p-6 w-full max-w-md shadow-xl"
        dir={isRTL ? 'rtl' : 'ltr'}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">
            {initialData ? t('editItem') : t('addItem')}
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('itemCode')}
            </label>
            <input
              type="text"
              value={formData.external_id}
              onChange={(e) => setFormData({ ...formData, item_id: e.target.value })}
              className={`w-full p-2 border rounded-lg ${
                errors.item_id ? 'border-red-500' : 'border-gray-300'
              } ${!!initialData ? 'bg-gray-100' : ''}`}
              disabled={!!initialData}
            />
            {errors.item_id && (
              <p className="text-red-500 text-sm mt-1">{errors.item_id}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('name')}
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className={`w-full p-2 border rounded-lg ${
                errors.name ? 'border-red-500' : 'border-gray-300'
              } ${!!initialData ? 'bg-gray-100' : ''}`}
              disabled={!!initialData}
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">{errors.name}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('description')}
            </label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              className="w-full p-2 border rounded-lg border-gray-300"
              rows={3}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Category
            </label>
            <div className="relative">
              <select
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                className={`w-full p-2 border rounded-lg ${
                  errors.category ? 'border-red-500' : 'border-gray-300'
                }`}
              >
                <option value="">Select a category</option>
                {CATEGORIES.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
              <Tags className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            {errors.category && (
              <p className="text-red-500 text-sm mt-1">{errors.category}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Image URL
            </label>
            <div className="relative">
              <input
                type="text"
                value={formData.image_url}
                onChange={(e) => setFormData({ ...formData, image_url: e.target.value })}
                className={`w-full pl-10 pr-4 py-2 border rounded-lg ${
                  errors.image_url ? 'border-red-500' : 'border-gray-300'
                }`}
                placeholder="https://example.com/image.jpg"
              />
              <Image className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            {errors.image_url && (
              <p className="text-red-500 text-sm mt-1">{errors.image_url}</p>
            )}
          </div>

          <div>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.is_available}
                onChange={(e) => setFormData({ ...formData, is_available: e.target.checked })}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 h-4 w-4"
              />
              <span className="text-sm font-medium text-gray-700">Available for sale</span>
            </label>
          </div>

          {errors.submit && (
            <div className="bg-red-50 border border-red-400 rounded-lg p-3">
              <div className="flex items-center gap-2">
                <AlertCircle className="h-5 w-5 text-red-600" />
                <p className="text-red-700">{errors.submit}</p>
              </div>
            </div>
          )}

          <div className={`flex justify-end gap-3 ${isRTL ? 'flex-row-reverse' : ''}`}>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50 transition-colors"
              disabled={isSubmitting}
            >
              {t('cancel')}
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                transition-colors flex items-center gap-2 disabled:bg-blue-400"
              disabled={isSubmitting}
            >
              {isSubmitting && <Loader2 className="h-4 w-4 animate-spin" />}
              {isSubmitting ? t('saving') : (initialData ? t('update') : t('add'))}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ItemModal;