import React from 'react';
import { Filter } from 'lucide-react';

const FilterButton = ({ active, onClick, disabled }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`p-1 rounded hover:bg-gray-100 ${
      active ? 'text-blue-600' : 'text-gray-400'
    } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    title={active ? 'Show all items' : 'Show only items with data'}
  >
    <Filter className="h-4 w-4" />
  </button>
);

export default FilterButton;