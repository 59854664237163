import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../translations/translations';
import { X, Plus, Minus, Trash2, AlertCircle } from 'lucide-react';
import apiService from '../../services/api';

const OrderModal = ({ isOpen, onClose, onSubmit, customers }) => {
  const { language } = useLanguage();
  const t = (key) => translations[key]?.[language] || key;
  const [error, setError] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [orderItems, setOrderItems] = useState([{
    id: Date.now(),
    itemId: '',
    selectedItem: null,
    quantity: 1,
  }]);
  const [notes, setNotes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [availableItems, setAvailableItems] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const fetchItems = async () => {
        try {
          const response = await apiService.request('items', { method: 'GET' });
          setAvailableItems(response || []);
          resetForm();
        } catch (error) {
          console.error('Error fetching items:', error);
        }
      };
      fetchItems();
    }
  }, [isOpen]);

  const resetForm = () => {
    setError('');
    setSelectedCustomer('');
    setOrderItems([{
      id: Date.now(),
      itemId: '',
      selectedItem: null,
      quantity: 1,
    }]);
    setNotes('');
    setIsSubmitting(false);
  };

  const handleItemIdChange = (itemId, value) => {
    const foundItem = availableItems.find(item => 
      item.external_id.toLowerCase() === value.toLowerCase() ||
      item.name.toLowerCase() === value.toLowerCase()
    );

    setOrderItems(orderItems.map(item => {
      if (item.id === itemId) {
        return {
          ...item,
          itemId: value,
          selectedItem: foundItem || null
        };
      }
      return item;
    }));
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    if (newQuantity < 1) return;
    setOrderItems(orderItems.map(item => 
      item.id === itemId ? { ...item, quantity: newQuantity } : item
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    try {
      if (!selectedCustomer) {
        throw new Error(t('selectCustomerRequired'));
      }

      const validItems = orderItems.filter(item => item.selectedItem);
      if (validItems.length === 0) {
        throw new Error(t('noValidItems'));
      }

      const customer = customers.find(c => c.customer_id === selectedCustomer);
      const formattedItems = validItems.map(item => ({
        item_external_id: item.selectedItem.external_id,
        item_name: item.selectedItem.name,
        quantity: item.quantity,
        price: item.selectedItem.price,
        total: item.quantity * item.selectedItem.price
      }));

      await onSubmit({
        customer_id: selectedCustomer,
        customer_name: customer.name,
        items: formattedItems,
        notes,
        total_amount: formattedItems.reduce((sum, item) => sum + item.total, 0)
      });
      onClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
        
        <div className="relative bg-white rounded-lg w-full max-w-2xl">
          <div className="flex justify-between items-center p-4 border-b">
            <h3 className="text-lg font-semibold">{t('createNewOrder')}</h3>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="h-5 w-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="p-4">
            {error && (
              <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center gap-2">
                <AlertCircle className="h-5 w-5" />
                {error}
              </div>
            )}

            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium">
                {t('selectCustomer')}
              </label>
              <select
                value={selectedCustomer}
                onChange={(e) => setSelectedCustomer(e.target.value)}
                className="w-full p-2 border rounded-md"
                disabled={isSubmitting}
              >
                <option value="">{t('selectCustomer')}</option>
                {customers.map(customer => (
                  <option key={customer.customer_id} value={customer.customer_id}>
                    {customer.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <label className="text-sm font-medium">{t('items')}</label>
                <button
                  type="button"
                  onClick={() => setOrderItems([...orderItems, {
                    id: Date.now(),
                    itemId: '',
                    selectedItem: null,
                    quantity: 1,
                  }])}
                  className="px-3 py-1 text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1"
                >
                  <Plus className="h-4 w-4" />
                  {t('addItem')}
                </button>
              </div>

              {orderItems.map((item, index) => (
                <div key={item.id} className="mb-4 p-4 border rounded-lg">
                  <div className="flex gap-4 mb-2">
                    <div className="flex-1">
                      <input
                        list={`items-${item.id}`}
                        value={item.itemId}
                        onChange={(e) => handleItemIdChange(item.id, e.target.value)}
                        className="w-full p-2 border rounded-md"
                        placeholder={t('enterItemIdOrName')}
                        disabled={isSubmitting}
                      />
                      <datalist id={`items-${item.id}`}>
                        {availableItems.map(availableItem => (
                          <option key={availableItem.external_id} value={availableItem.external_id}>
                            {availableItem.name}
                          </option>
                        ))}
                      </datalist>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        type="button"
                        onClick={() => handleQuantityChange(item.id, item.quantity - 1)}
                        className="p-2 text-gray-500 hover:text-gray-700"
                        disabled={isSubmitting}
                      >
                        <Minus className="h-4 w-4" />
                      </button>
                      <input
                        type="number"
                        min="1"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value, 10))}
                        className="w-16 p-2 border rounded-md text-center"
                        disabled={isSubmitting}
                      />
                      <button
                        type="button"
                        onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
                        className="p-2 text-gray-500 hover:text-gray-700"
                        disabled={isSubmitting}
                      >
                        <Plus className="h-4 w-4" />
                      </button>
                    </div>
                    {orderItems.length > 1 && (
                      <button
                        type="button"
                        onClick={() => setOrderItems(orderItems.filter(i => i.id !== item.id))}
                        className="p-2 text-red-600 hover:text-red-800"
                        disabled={isSubmitting}
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                  {item.selectedItem && (
                    <div className="text-sm text-gray-500">
                      {item.selectedItem.name} - 
                      ₪{item.selectedItem.price} × {item.quantity} = 
                      ₪{(item.quantity * item.selectedItem.price).toFixed(2)}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium">
                {t('notes')}
              </label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="w-full p-2 border rounded-md"
                rows="3"
                disabled={isSubmitting}
              ></textarea>
            </div>

            <div className="mb-4 text-right">
              <span className="text-lg font-semibold">
                {t('total')}: ₪{orderItems.reduce((sum, item) => 
                  sum + (item.selectedItem ? item.quantity * item.selectedItem.price : 0), 0).toFixed(2)}
              </span>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
                disabled={isSubmitting}
              >
                {t('cancel')}
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
                disabled={isSubmitting}
              >
                {isSubmitting ? t('creating') : t('createOrder')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;