import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronRight, Edit, Trash2, PowerOff } from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';
import { translations } from '../../translations/translations';
import apiService from '../../services/api';

const InvoiceItems = ({ items }) => {
  return (
    <div className="pl-8 mt-2">
      <table className="w-full text-sm">
        <thead>
          <tr className="bg-gray-50">
            <th className="p-2 text-left">Item Name</th>
            <th className="p-2 text-right">Quantity</th>
            <th className="p-2 text-right">Price</th>
            <th className="p-2 text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index} className="border-t">
              <td className="p-2">{item.item_name}</td>
              <td className="p-2 text-right">{item.quantity}</td>
              <td className="p-2 text-right">{item.price}₪</td>
              <td className="p-2 text-right">{item.total}₪</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Invoice = ({ invoice, isExpanded, onToggle }) => {
  const totalAmount = invoice.items?.reduce((sum, item) => {
    const itemTotal = parseFloat(item.total) || 0;
    return sum + itemTotal;
  }, 0);

  return (
    <div className="border-t">
      <div 
        className="p-4 bg-gray-50 cursor-pointer hover:bg-gray-100 flex items-center"
        onClick={onToggle}
      >
        <ChevronRight 
          className={`w-5 h-5 mr-2 transition-transform ${isExpanded ? 'rotate-90' : ''}`}
        />
        <div className="flex-1 grid grid-cols-4 gap-4">
          <span>Invoice</span>
          <span>{invoice.invoice_date}</span>
          <span>{Number(totalAmount).toFixed(2)}₪</span>
          <span className={`px-2 py-1 rounded-full text-sm text-center
            ${invoice.status === 'PAID' ? 'bg-green-100 text-green-800' : 
              invoice.status === 'PENDING' ? 'bg-yellow-100 text-yellow-800' :
              'bg-red-100 text-red-800'}`}>
            {invoice.status}
          </span>
        </div>
      </div>
      {isExpanded && <InvoiceItems items={invoice.items} />}
    </div>
  );
};
const ExpandableCustomerRow = ({ customer, isRTL, onEdit, onDelete, onToggleActive, isToggling }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [expandedInvoices, setExpandedInvoices] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const { language } = useLanguage();
  const t = key => translations[key]?.[language] || key;

  useEffect(() => {
    if (isExpanded && invoices.length === 0) {
      fetchInvoices();
    }
  }, [isExpanded]);

  const fetchInvoices = async () => {
    setIsLoading(true);
    try {
      const response = await apiService.getCustomerInvoices(customer.customer_id);
      setInvoices(response.invoices);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleInvoice = (invoiceId) => {
    setExpandedInvoices(prev => {
      const next = new Set(prev);
      if (next.has(invoiceId)) {
        next.delete(invoiceId);
      } else {
        next.add(invoiceId);
      }
      return next;
    });
  };

  return (
    <>
      <tr className="hover:bg-gray-50 transition-colors">
        <td className="px-6 py-4 text-sm text-gray-500">
          {customer.customer_id}
        </td>
        <td className="px-6 py-4">{customer.name}</td>
        <td className="px-6 py-4">{customer.email}</td>
        <td className="px-6 py-4">{customer.phone}</td>
        <td className="px-6 py-4">{customer.address}</td>
        <td className="px-6 py-4">
          <div className="flex items-center gap-2">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onEdit(customer);
              }}
              className="text-blue-600 hover:text-blue-800"
            >
              <Edit className="h-5 w-5" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onToggleActive(customer);
              }}
              disabled={isToggling}
              className={`${
                customer.is_active ? 'text-gray-400 hover:text-gray-600' : 'text-red-600 hover:text-red-800'
              } ${isToggling ? 'opacity-50 cursor-not-allowed' : ''}`}
              title={customer.is_active ? t('deactivateCustomer') : t('activateCustomer')}
            >
              <PowerOff className={`h-5 w-5 ${isToggling ? 'animate-spin' : ''}`} />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(customer);
              }}
              className="text-red-600 hover:text-red-800"
            >
              <Trash2 className="h-5 w-5" />
            </button>
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-blue-600 hover:text-blue-800 ml-2"
            >
              {isExpanded ? 
                <ChevronDown className="h-5 w-5" /> : 
                <ChevronRight className="h-5 w-5" />
              }
            </button>
          </div>
        </td>
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan="6" className="bg-gray-50">
            <div className="px-6 py-4">
              <h3 className="text-lg font-medium mb-4">{t('customerInvoices')}</h3>
              {isLoading ? (
                <div className="text-center py-4">{t('loadingInvoices')}</div>
              ) : invoices.length === 0 ? (
                <div className="text-center py-4">{t('noInvoices')}</div>
              ) : (
                <div className="space-y-2">
                  {invoices.map(invoice => (
                    <Invoice
                      key={invoice.invoice_id}
                      invoice={invoice}
                      isExpanded={expandedInvoices.has(invoice.invoice_id)}
                      onToggle={() => toggleInvoice(invoice.invoice_id)}
                    />
                  ))}
                </div>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default ExpandableCustomerRow;