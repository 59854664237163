import React, { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/translations';
import { Plus, Trash2, Loader2, RefreshCw, AlertCircle } from 'lucide-react';
import apiService from '../services/api';
import ScaleModal from './ScaleModal';
import DeleteConfirmationModal from './modals/DeleteConfirmationModal';
import ScaleList from './ScaleList';

const ScalesManagement = () => {
  const [registeredScales, setRegisteredScales] = useState([]);
  const [measurements, setMeasurements] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedScale, setSelectedScale] = useState(null);

  const { language } = useLanguage();
  const t = (key) => translations[key]?.[language] || `Missing translation: ${key}`;
  const isRTL = language === 'he';

  const fetchMeasurements = async (scales) => {
    const measurementPromises = scales
      .filter(scale => scale.scale_id)
      .map(scale => 
        apiService.request(`measures/scale/${scale.scale_id}/latest`, {
          method: 'GET'
        }).catch(err => {
          console.error(`Error fetching measurement for scale ${scale.scale_id}:`, err);
          return null;
        })
      );

    const results = await Promise.allSettled(measurementPromises);
    const newMeasurements = {};
    
    results.forEach((result, index) => {
      if (result.status === 'fulfilled' && result.value) {
        newMeasurements[scales[index].scale_id] = result.value;
      }
    });

    return newMeasurements;
  };

  const fetchData = async () => {
    try {
      setError(null);
      const scales = await apiService.getScales();
      const measurements = await fetchMeasurements(scales);
      
      setRegisteredScales(scales);
      setMeasurements(measurements);
    } catch (err) {
      setError(err.message || 'Failed to fetch scales data');
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchData();
  };

  const showSuccessMessage = (message) => {
    setSuccessMessage(message);
    setTimeout(() => setSuccessMessage(''), 3000);
  };

  const handleAddScale = async (scaleData) => {
    try {
      await apiService.createScale({
        id: scaleData.id,
        name: scaleData.name
      });
      await fetchData();
      showSuccessMessage('Scale added successfully');
    } catch (err) {
      throw new Error(err.message || 'Failed to add scale');
    }
  };

  const handleDeleteScale = async () => {
    try {
      await apiService.deleteScale(selectedScale.scale_id);
      setRegisteredScales(prev => prev.filter(s => s.scale_id !== selectedScale.scale_id));
      setIsDeleteModalOpen(false);
      setSelectedScale(null);
      showSuccessMessage('Scale deleted successfully');
    } catch (err) {
      setError(err.message || 'Failed to delete scale');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto" dir={isRTL ? 'rtl' : 'ltr'}>
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold">Scales Management</h2>
            <p className="text-gray-600 mt-1">Manage and monitor your scales</p>
          </div>
          <div className="flex gap-2">
            <button
              onClick={handleRefresh}
              className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-800"
              disabled={isRefreshing}
            >
              <RefreshCw className={`h-5 w-5 ${isRefreshing ? 'animate-spin' : ''}`} />
              Refresh
            </button>
            <button
              onClick={() => setIsModalOpen(true)}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Plus size={20} />
              Add Scale
            </button>
          </div>
        </div>
      </div>

      {successMessage && (
        <div className="mb-6 bg-green-50 border border-green-400 rounded-lg p-4">
          <p className="text-green-700">{successMessage}</p>
        </div>
      )}

      {error && (
        <div className="mb-6 bg-red-50 border border-red-400 rounded-lg p-4 flex items-center">
          <AlertCircle className="h-5 w-5 text-red-600 mr-2" />
          <p className="text-red-700">{error}</p>
        </div>
      )}

      <ScaleList 
        scales={registeredScales} 
        measurements={measurements}
        onDeleteClick={(scale) => {
          setSelectedScale(scale);
          setIsDeleteModalOpen(true);
        }}
      />

      <ScaleModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAddScale}
      />

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setSelectedScale(null);
        }}
        onConfirm={handleDeleteScale}
        title="Delete Scale"
        message="Are you sure you want to delete this scale? This action cannot be undone."
      />
    </div>
  );
};

export default ScalesManagement;