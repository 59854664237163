import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import apiService from '../../services/api';
import { tokenService } from '../../services/tokenService';

const GoogleSignInButton = ({ onSuccess, onError }) => {
  const { signInWithGoogle, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const formatName = (name) => {
    if (!name) return 'User';
    
    // Remove any special characters and extra spaces
    const cleanName = name.replace(/[^\w\s]/g, '').trim();
    
    // Ensure the name starts with a letter
    if (!/^[a-zA-Z]/.test(cleanName)) {
      return 'User_' + cleanName;
    }
    
    // Replace spaces with underscores and ensure proper format
    return cleanName.replace(/\s+/g, '_');
  };

  const handleAuthSuccess = async (authData) => {
    try {
      // Store tokens
      tokenService.setTokens({
        accessToken: authData.access_token,
        idToken: authData.id_token,
        refreshToken: authData.refresh_token,
        expiresIn: authData.expires_in
      });

      // Get user info from token and format the name
      const payload = JSON.parse(atob(authData.id_token.split('.')[1]));
      const formattedName = formatName(payload.name);
      const userData = {
        email: payload.email,
        name: formattedName,
        sub: payload.sub
      };

      // Update auth context
      setUser(userData);

      // Call optional success callback
      if (onSuccess) {
        onSuccess(authData);
      }

      // Navigate to main dashboard
      navigate('/dashboard');
    } catch (err) {
      console.error('Error handling auth success:', err);
      setError('Failed to process login');
      if (onError) {
        onError(err);
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      setIsLoading(true);
      try {
        // Get user info from Google
        const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${response.access_token}` },
        });
        
        if (!userInfoResponse.ok) {
          throw new Error('Failed to get user info from Google');
        }
        
        const userInfo = await userInfoResponse.json();
        
        // Format the name before sending to backend
        userInfo.name = formatName(userInfo.name);
        
        // Exchange Google token for Cognito tokens
        const backendResponse = await fetch(`${apiService.baseUrl}/auth/google/callback`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            code: response.access_token,
            userInfo
          }),
        });

        if (!backendResponse.ok) {
          const errorData = await backendResponse.json();
          throw new Error(errorData.error || 'Authentication failed');
        }

        const authData = await backendResponse.json();
        await signInWithGoogle(authData);
        
        // Handle successful authentication
        await handleAuthSuccess(authData);
        
      } catch (err) {
        console.error('Google sign in error:', err);
        const errorMessage = err.message || 'Failed to sign in with Google';
        setError(errorMessage);
        if (onError) {
          onError(errorMessage);
        }
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      console.error('Google Login Error:', error);
      const errorMessage = 'Failed to sign in with Google';
      setError(errorMessage);
      if (onError) {
        onError(errorMessage);
      }
    }
  });

  return (
    <div className="w-full">
      {error && (
        <div className="mb-4 bg-red-50 border border-red-400 rounded-lg p-3">
          <p className="text-red-700 text-sm">{error}</p>
        </div>
      )}
      
      <button
        onClick={() => login()}
        disabled={isLoading}
        className="w-full flex items-center justify-center gap-2 px-4 py-2 border border-gray-300 
          rounded-lg bg-white hover:bg-gray-50 transition-colors disabled:opacity-50"
      >
        {isLoading ? (
          <div className="w-5 h-5 border-2 border-gray-600 border-t-transparent rounded-full animate-spin" />
        ) : (
          <svg className="w-5 h-5" viewBox="0 0 24 24">
            <path
              fill="#4285F4"
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
            />
            <path
              fill="#34A853"
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
            />
            <path
              fill="#FBBC05"
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
            />
            <path
              fill="#EA4335"
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
            />
          </svg>
        )}
        Sign in with Google
      </button>
    </div>
  );
};

export default GoogleSignInButton;